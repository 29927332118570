/* clear-sans-100normal - latin */
@font-face {
  font-family: 'Clear Sans';
  font-style: normal;
  font-weight: 100;
  src: url('./files/clear-sans-100.eot'); /* IE9 Compat Modes */
  src:
    local('Clear Sans Thin normal'),
    local('Clear Sans-Thinnormal'),
    url('./files/clear-sans-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/clear-sans-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/clear-sans-100.woff') format('woff'), /* Modern Browsers */
    url('./files/clear-sans-100.svg#ClearSans') format('svg'); /* Legacy iOS */
}

/* clear-sans-300normal - latin */
@font-face {
  font-family: 'Clear Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./files/clear-sans-300.eot'); /* IE9 Compat Modes */
  src:
    local('Clear Sans Light normal'),
    local('Clear Sans-Lightnormal'),
    url('./files/clear-sans-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/clear-sans-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/clear-sans-300.woff') format('woff'), /* Modern Browsers */
    url('./files/clear-sans-300.svg#ClearSans') format('svg'); /* Legacy iOS */
}

/* clear-sans-400normal - latin */
@font-face {
  font-family: 'Clear Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./files/clear-sans-400.eot'); /* IE9 Compat Modes */
  src:
    local('Clear Sans Regular normal'),
    local('Clear Sans-Regularnormal'),
    url('./files/clear-sans-400.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/clear-sans-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/clear-sans-400.woff') format('woff'), /* Modern Browsers */
    url('./files/clear-sans-400.svg#ClearSans') format('svg'); /* Legacy iOS */
}

/* clear-sans-400italic - latin */
@font-face {
  font-family: 'Clear Sans';
  font-style: italic;
  font-weight: 400;
  src: url('./files/clear-sans-400italic.eot'); /* IE9 Compat Modes */
  src:
    local('Clear Sans Regular italic'),
    local('Clear Sans-Regularitalic'),
    url('./files/clear-sans-400italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/clear-sans-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/clear-sans-400italic.woff') format('woff'), /* Modern Browsers */
    url('./files/clear-sans-400italic.svg#ClearSans') format('svg'); /* Legacy iOS */
}

/* clear-sans-500normal - latin */
@font-face {
  font-family: 'Clear Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./files/clear-sans-500.eot'); /* IE9 Compat Modes */
  src:
    local('Clear Sans Medium normal'),
    local('Clear Sans-Mediumnormal'),
    url('./files/clear-sans-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/clear-sans-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/clear-sans-500.woff') format('woff'), /* Modern Browsers */
    url('./files/clear-sans-500.svg#ClearSans') format('svg'); /* Legacy iOS */
}

/* clear-sans-500italic - latin */
@font-face {
  font-family: 'Clear Sans';
  font-style: italic;
  font-weight: 500;
  src: url('./files/clear-sans-500italic.eot'); /* IE9 Compat Modes */
  src:
    local('Clear Sans Medium italic'),
    local('Clear Sans-Mediumitalic'),
    url('./files/clear-sans-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/clear-sans-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/clear-sans-500italic.woff') format('woff'), /* Modern Browsers */
    url('./files/clear-sans-500italic.svg#ClearSans') format('svg'); /* Legacy iOS */
}

/* clear-sans-700normal - latin */
@font-face {
  font-family: 'Clear Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./files/clear-sans-700.eot'); /* IE9 Compat Modes */
  src:
    local('Clear Sans Bold normal'),
    local('Clear Sans-Boldnormal'),
    url('./files/clear-sans-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/clear-sans-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/clear-sans-700.woff') format('woff'), /* Modern Browsers */
    url('./files/clear-sans-700.svg#ClearSans') format('svg'); /* Legacy iOS */
}

/* clear-sans-700italic - latin */
@font-face {
  font-family: 'Clear Sans';
  font-style: italic;
  font-weight: 700;
  src: url('./files/clear-sans-700italic.eot'); /* IE9 Compat Modes */
  src:
    local('Clear Sans Bold italic'),
    local('Clear Sans-Bolditalic'),
    url('./files/clear-sans-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./files/clear-sans-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/clear-sans-700italic.woff') format('woff'), /* Modern Browsers */
    url('./files/clear-sans-700italic.svg#ClearSans') format('svg'); /* Legacy iOS */
}
